@layer base {
  .contactsWrapper {
  display: flex;
  flex-direction: column;
  margin-bottom: var(--sk-space-48);
  text-align: center;
}

.contactsTitle {
  margin-bottom: var(--sk-space-16);
  word-break: break-word;
}

.contactsContainer {
  display: grid;
  grid-gap: var(--sk-space-24) var(--sk-space-16);
  grid-template-columns: 1fr;
  padding: 0;
  list-style-type: none;
}

.contactsItem {
  box-sizing: border-box;
  padding: var(--sk-space-32) var(--sk-space-24);
  border-left: 1px solid var(--sk-color-grey-100);
  background: var(--sk-color-white);
  text-align: start;
  word-break: break-word;
}

.icon {
  flex-shrink: 0;
  margin-right: var(--sk-space-8);
}

.contactHeader {
  margin-bottom: var(--sk-space-24);
}

.contactDetails {
  display: flex;
  margin-bottom: var(--sk-space-12);
}

.contactsLinks {
  all: unset;
  cursor: pointer;
}

.email {
  display: flex;
}

.showMoreLess {
  margin-top: var(--sk-space-16);
}

@media (--sk-small-viewport-only) {
  .contactsHidden div:nth-child(n + 4) {
    display: none;
  }
}

@media (--sk-medium-viewport) {
  .showMoreLess {
    display: none;
  }

  .contactsContainer {
    grid-template-columns: 1fr 1fr;
  }

  .contactsContainerOneContact {
    display: flex;
    align-self: center;
    width: 500px;
  }

  .contactsTitle {
    margin-bottom: var(--sk-space-32);
  }

  .contactsItem {
    flex: 1 0 40%;
  }

  .contactDetails {
    margin-bottom: var(--sk-space-16);
  }
}

@media (--sk-large-viewport) {
  .contactsWrapper {
    margin-bottom: var(--sk-space-80);
  }

  .contactsTitle {
    margin-bottom: var(--sk-space-40);
  }

  .contactsItem {
    flex: 1 0 40%;
  }
}

}