@layer base {
  .card {
  --sk-data-display-card-color-border-default-light: var(--sk-color-black);
  --sk-data-display-card-body-content-gap: var(--sk-space-12);
  position: relative;
}

.header {
  margin-bottom: var(--sk-space-16);
}

.body {
  display: flex;
  flex-direction: column;
}

.link {
  /* see https://css-tricks.com/almanac/properties/l/line-clamp/ */
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  color: var(--sk-color-black);
  line-clamp: 3;
  text-decoration: none;
  text-overflow: ellipsis;
}

.link:focus-visible {
  outline-color: var(--sk-navigation-link-color-border-focus-light);
}

.link::before {
  content: "";
  position: absolute;
  inset: 0;
  transition: border-color 200ms;
  cursor: pointer;
}

.bodyContent {
  gap: var(--sk-space-4);
}

.footer {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

@media (--sk-medium-viewport) {
  .withBigDateDisplay .body {
    flex-direction: row;
  }
}

}