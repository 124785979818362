@layer base {
  .partner {
  display: flex;
  align-items: center;
  gap: var(--sk-space-8);
}

.iconTextContainer {
  display: flex;
  align-items: flex-start;
  gap: var(--sk-space-8);
}

.icon {
  flex-shrink: 0;
  margin-top: var(--sk-space-2);
}

.iconRegistrationStatus {
  color: var(--sk-color-success-500);
}

.text {
  /* see https://css-tricks.com/almanac/properties/l/line-clamp/ */
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  line-clamp: 2;
  overflow: hidden;
  text-overflow: ellipsis;
}

}