@layer base {
  .button {
  z-index: var(--z-up-in-the-current-stacking-context); /* Above the .EventCard_link::before pseudo-element.
  .EventCard_link::before is covering the card surface, Button should keep its rollover/focus effect
  even if it leads to the same url as link */
  margin-left: auto; /* This aligns the button to the right event if it's the only child */
  padding: var(--sk-space-12);
}

.buttonLarge {
  display: none;
}

.icon {
  width: var(--sk-size-24);
  height: var(--sk-size-24);
}

@media (--sk-medium-viewport) {
  /* reset to Spark's Button paddings */
  .button {
    padding: var(--sk-space-12) var(--sk-space-16);
  }

  /* reset to Spark's Button display */
  .buttonLarge {
    display: flex;
  }

  .icon {
    width: var(--sk-size-20);
  }
}

}